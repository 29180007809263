const startingRoster = [
    {
        name:"Sir Alex",
        description:"Sir Alex is part of the new generation of romanian Djs,rising through the music scene with his high energy grooves and explosive DJ sets.Being one of residents at BABYLON events,he developed his own distinctive sound based out of his influences and passions, thing that has made him a favorite among fans. He played music in several places,suchas Kristal Club, Gradina Monteoru, Shade Events, Love Life Events, Rave Timisoara, High Waves Sounds Cluj, Gatsby Timisoara, ilCharlie Iasi and much more. He has delighted us throughout his career with songs loved by his fans, songs like “Ala ala ala“, “MiddleChild“, “Pure honey“ and many others.", 
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716825430/blackWhite/photo_5956372156784951746_y-modified_bijflc.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1818131970&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/sir____alex____/",
            tiktok:"https://www.tiktok.com/@siralex151",
        },
        id: 1
    },
    {
    name:"Manolaco",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718018061/photo_5996778968587551497_y_qr2wuw.jpg",
        description:"Manolaco is an esteemed DJ and producer from Athens, Greece, being resident at Blend. He discovered his passion for music at the age of 17 and has since dedicated his life to it. With an impressive career spanning over two decades, Manolaco has established himself as a prominent figure in the music scene. He had the privilege of sharing the stage with the biggest names in the industry such as Marco Carola, Loco Dice, Richie Hawtin, Luciano, Ricardo Villalobos, Black Coffee or Solomun, just to name a few. His productions have found homes on impressive labels such as Nervous, Moan, Rush, Sanity, Clarisse, Rebellion, and many many more.",
        spotifyLink:"https://open.spotify.com/embed/artist/4x5GoGU0k0q1jOrb95scqZ?utm_source=generator",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1386338338&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        beatportLink:"https://embed.beatport.com/?id=4095065&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/track=1880798922/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/track=3094256440/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/manolaco_ofc/",
            facebook:"https://www.facebook.com/manolaco.official",
            youtube:"https://www.youtube.com/c/Manolaco",
            tiktok:"https://www.tiktok.com/@manolaco.official"
        },
        id: 4
    },
    /*{
        name:"Shaf Huse",
        description:"Shaf Huse is a producer and DJ from Milan, being resident at Too long. One of his biggest hits, Mavado caused a sensation in Ibiza last year. Support came by none other than Luciano, Loco Dice and The Martinez Brothers, following other tracks like “Get Em Up”, “Go“ or “Power To The People” with which he has attracted a lot of attention. He played in many venues,all around the globe, such as London, North, Amsterdam, Santiago and Romania.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1714705750/blackWhite/Untitled_Project_8_zotjla.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1746508524&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        beatportLink:"https://embed.beatport.com/?id=4130448&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=3189857832/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=141248220/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/album=1714570852/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/shafhuse/",
            facebook:"https://www.facebook.com/shafhuse/events/",
            tiktok:"https://www.tiktok.com/@shafhuse",
            youtube:"https://www.youtube.com/@shafhusemusic",
        },
        id:6
    },*/
    /*{
        name:"Andreea Ndr",
        description:"Andreea NDR is a new romanian DJ discovered by our agency. Her career started recently through her debut at BABYLON Events. She will create a memmorable musical journey for you. Her strong points being her passion and her joyful energy with lots of vibes.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1714701074/blackWhite/Untitled_Project_1_nrgxw8.jpg",
        socialMedia:{
            instagram:"https://www.instagram.com/andreea.ndr6/",
        },
        id:7
    },*/
    {
        name:"Governor",
        
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1714700872/blackWhite/iw0bgz25y9ef1w76zsdq.jpg",
        description:"Driven by his deep passion for music, Governor is a romanian DJ and producer that evolved from a music lover to a party resident at Babylon Events, creating and maintaining a memorable party atmosphere. His pursuit of excellence and collaborative spirit define his creative approach. Besides providing music for events/festivals (Pe Coclauri, UNDRGRD, Love Life Events, Shade Events, BABYLON Events), he also mixed live music on local radio stations, such as Extravaganza Radio, Camara Radio and Radio Deea.He teamed up with a diverse range of exceptional artists, forging musical connections that include Shaf Huse, Sllash and Doppe, Dobrikan, Aydee, The Gang Raw, Christian Lepah, Alex Cicada, Pol, Cezar Stefan, Sir Alex, Rhem,Adrian Eftimie and Tulvan. His goal is to make you groove and keep you hooked.",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1468248289&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/governor.music/",
            youtube:"https://www.youtube.com/c/SunGovernor",
            tiktok:"https://www.tiktok.com/@governor.music?is_from_webapp=1&sender_device=pc",
            facebook:"https://www.facebook.com/Governor.Music.Official",
        },
        id:8
    },
    {
        name:"Vladutz",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716825921/photo_5956372156784951762_x-modified_msphj2.jpg",
        description:"Vladutz is a young promise DJ from Romania discovered by us. His passion started in 2016 and since then he has been spinning records focusing on electronic music styles reinterpreted by the Romanian (ro-minimal) groove, recently combining minimal tech with Romanian percussion and voices, becoming a resident for BABYLON Events.",
        socialMedia:{
            instagram:"https://www.instagram.com/1vladutz_/",
        },
        id:9
    },
    {
        name:"Hutu Music",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716827164/image00003-modified_pznksi.jpg",
        description:"HUTU is a Dutch-based DJ and Producer born and raised in ‘Rotterdam. He played venues as, Toffler, Now&Wow, Factory010, Madam and festivals like Blijdorp and Expedition, where he and his friends hosted they’re own stage named “Klein Berlijn”.   HUTU is not only a part of the scene, but he is also getting known for his releases on Kubbo records, Unnamed & Unknown, BNN, Distance and his upcoming release on NoZzo. His productions are known for its Rolling basslines, Filthy percussion and energetic vocals. And his music is being supported by some of the big players like, Joseph Capriati, Franky Rizardo, Tomi&Kesh and Manda Moore just to name a few.",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1762553148&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        beatportLink:"https://embed.beatport.com/?id=4421623&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=3341262987/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/hutu.music/",
            tiktok:"https://www.tiktok.com/@hutumusic",

        },
        id:10
    },
    {
        name:"Blackyassine",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716900356/acgyq6lrura6lpviqlkb.jpg",
        description:'Blackyassine is a DJ and producer,born in Bologna,to a tunisian family.He became pasionate about electronic music  from a young age. Over the years,he moved to London where he started playing in globally known clubs like Ministry of Sound,E1,Egg London,Orange Yard and many more. Throughout his career,he has delighted us with songs cherished by his fans,including hits like "Get It Right","Dime to stile","Confetti".',
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/801195034&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/blackyassineuk?igsh=d3g0OXF6MDM1d3Fp&utm_sourc%20e=qr.",
            tiktok:"https://www.tiktok.com/@blackyassine_"
        },
        id:11
    },
    {
        name:"Cotoraci",
        description:'Master in manipulating sound, Cotoraci stands out as one of the most well-established Romanian artists in his domain. Born in Arad, he first encountered music at the tender age of five through piano lessons. In 1996, he embarked on a journey that would lead to a long-lasting DJ career in an open format. Starting in 2005, he also showcased his talent as an MC, which brought him to Bucharest and saw him headlining every major club in the country. In 2018, he chose to narrow his focus to electronic music and shifted his efforts to the studio, producing his own tracks("Falcon","Sin control","Muevelo") which have gained support from key figures in the industry,such as Paco Osuna,Joseph Capriati,Marco Carola and many more.As a result, he was able to be present at international music festivals, clubs, and events relevant to his area of expertise.',
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1716902609/176A8776-modified_nl50t0.jpg",
        spotifyLink:"https://open.spotify.com/embed/artist/4BlWtG1Dq7AMgdgNMt97ID?utm_source=generator&theme=0",
        beatportLink:"https://embed.beatport.com/?id=4008215&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/track=1637871591/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/track=1095657903/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1413871318&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            youtube:"https://www.youtube.com/channel/UCzmSBa3hz2GuXoUCX1XY32Q",
            instagram:"https://www.instagram.com/cotoraci888/",
            facebook:"https://www.facebook.com/McExcite/?locale=ro_RO"
        },
        id:12,
    },
    {
        name:"EFI",
        description:"Driven by a passion for music from a young age, Efi is a new romanian DJ that made his debut at BABYLON Events.His passion started in 2016 and he has been spinning records with a focus on tech-house, minimal and deep tech since then.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718010343/photo_5931600825531089618_y-modified_ztd804.jpg",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=1639395639/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/",
        bandcampLink2:"https://bandcamp.com/EmbeddedPlayer/album=1639395639/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=2890227038/transparent=true/",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/908346748&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
        socialMedia:{
            instagram:"https://www.instagram.com/efisounds/",
            facebook:"https://www.facebook.com/efisounds/",
        },
        id:13,
    },
    {
        name:"Rin F",
        description:"Rin F is a Romanian DJ and producer that has been in contact with music from a very young age. From Tech-House to Deep-Tech and Minimal, he evolved very fast from the underground after-parties of Romania's clubbing capital to play in big clubs and events, such as Burning Man, Inside Festival, Kristal Club and Kudos Beach and in Florence and Rome, Italy. He has played next to artists like Pirupa, Neverdogs, Dennis Cruz, Andre Buljat and Reelow amongst others. In a very short tine, he has come a long way not only as a DJ but also a producer, with many releases, such as 'Morning Sunrise', 'Sweets For You' and many more.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718190640/photo_6001133958051512470_y_kp8bs3.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/559863402&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        beatportLink:"https://embed.beatport.com/?id=4508833&type=release",
        bandcampLink1:"https://bandcamp.com/EmbeddedPlayer/album=1410417102/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=2328563594/transparent=true/",
        socialMedia:{
            instagram:"https://www.instagram.com/rin_.f/",
            facebook:"https://www.facebook.com/rin.dj0",
            tiktok:"https://www.tiktok.com/@rin_.f",
            youtube:"https://www.youtube.com/@rinf159"
        },
        id:14
    },
    {
        name:"Alexandrescu",
        description:"Alexandrescu is a Romanian DJ, founder of Rockstar Collective and mastermind behind the Burning Man camp Vampire Empire. Known for his for his euphoric sets that meld vibrant rhythms with the soul of house music, he captured the hearts of his audience. His sets are not just performances, they are gatherings of kindred spirits united under the banner of profound beats and electrifying melodies.",
        image:"https://res.cloudinary.com/dsy9com82/image/upload/v1718195470/photo_6003385757865198251_m_b94m55.jpg",
        soundcloudLink:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1629869115&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        socialMedia:{
            instagram:"https://www.instagram.com/alexandrescu667/",
            facebook:"https://www.facebook.com/alexandrescu667",
            tiktok:"https://www.tiktok.com/@alexandrescu667?_t=8n8a11i7QfY&_r=1"
        },
        id:15
    }
]

startingRoster.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

module.exports = startingRoster
