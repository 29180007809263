import AnimatedCursor from "react-animated-cursor";
import useScreenSize from "./hooks/useScreenSize"
import Logo from "./imgs/logo.png"
import MetaPixel from "./components/metaPixel"
import Navbar from "./components/navbar"
import LandingPage from "./pages/home"
import Roster from "./pages/roster"
import ArtistPage from "./pages/artistPage"
import Contact from "./pages/contact"
import Info from "./pages/info"
import {BrowserRouter,Route,Switch} from "react-router-dom"
import "./App.css"

function App() {
  const screenSize = useScreenSize();
  return (
    <BrowserRouter>
    <div className="screen" style={{width:screenSize.width,height:screenSize.height}}>
      <div className="screen-cover" style={{width:screenSize.width,height:screenSize.height}}>
      <MetaPixel/>
      { screenSize.width > 900 && <AnimatedCursor
          color="255,255,255"
      innerSize={0}
      outerSize={16}
      outerScale={1.5}
      outerAlpha={1}
      />}
      <div className="logo-container" style={{width:screenSize.width}}><img src={Logo} style={{height:screenSize.height/6}}/></div>
      <div className="content" style={{width:screenSize.width}}>
        <Switch>
          <Route exact path="/"><LandingPage /></Route>
          <Route path="/roster"><Roster /></Route>
          <Route path="/artist/:id"><ArtistPage/></Route>
          <Route path="/contact"><Contact /></Route>
          <Route path="/about-us"><Info /></Route>
        </Switch>
      </div>
      <Navbar />
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;
