import "animate.css"
import "./main.css"
import useScreenSize from "../../hooks/useScreenSize"
import startingRoster from "../../components/artists"
import {Link} from "react-router-dom"

const Roster = () => {
    const screenSize = useScreenSize();
    return(
        <div className="rooster-list" style={{width:screenSize.width}}>
            {startingRoster.map((artist) => (
                <div className="artist animate__animated animate__fadeIn" key={artist.id}>
                    <Link to={`/artist/${artist.id}`}><img src={artist.image} className="artist-image"></img><div className="artist-name">{artist.name}</div></Link></div>
            ))}
        </div>
    )
}

export default Roster;