import "./main.css"
import {Link} from "react-router-dom"
import useScreenSize from "../../hooks/useScreenSize"
import { useEffect } from "react"

const LandingPage = () => {
    const screenSize = useScreenSize()
    return(
        <div className="lp-container" style={{width:screenSize.width}}>
        <h1>Who are we?</h1><br/>
        <p>Los Bandoleros is a booking and impresario agency based in Romania.Our team is made up of experienced artists and professional DJs with experience in the music industry and succesful events.At Los Bandoleros, we represent a dynamic roster of artists who are redefining the landscape of electronic music. Our artists are united by their passion and creativity.</p>
        <h1>Our past events:</h1>
        <p>At Los Bandoleros, we believe in creating unforgettable experiences that resonate with our audience and leave a lasting impression. We've teamed up with BABYLON EVENTS to arrange some extraordinary events. </p>
        <div className="events-images">
            <img src="https://res.cloudinary.com/dsy9com82/image/upload/v1718893110/Parties/Babylon%20Hollywood%20Villa%20Edition/photo1718877256_ew1aqu.jpg" className="event-image animate__animated animate__flash animate__infinite animate__slower"/>
            <img src="https://res.cloudinary.com/dsy9com82/image/upload/v1718893110/Parties/Babylon%20Hollywood%20Villa%20Edition/photo1718877256_2_yaput2.jpg" className="event-image animate__animated animate__flash animate__infinite animate__slower"/>
            <img src="https://res.cloudinary.com/dsy9com82/image/upload/v1718893110/Parties/Babylon%20Hollywood%20Villa%20Edition/photo1718877256_1_nas8fz.jpg" className="event-image animate__animated animate__flash animate__infinite animate__slower"/>
        </div>
        <h1>Why should you choose us?</h1>
        <p>At Los Bandoleros, we are not just another booking agency, we are a dynamic force of creativity, passion and dedication to the electronic music landscape.Here’s why partnering with us is the best choice for artists, event organizers, and music lovers alike:
    <br/><br/>• Our passion for music is authentic. We live and breathe electronic music. Our team is made up of industry professionals and enthusiasts who share a deep love for the beat. This passion drives us to go above and beyond in everything we do, from supporting new talents to creating unforgettable experiences.
    <br/><br/>• With years of experience and a sharp eye for  trends, we bring a mass of knowledge and insight to every project. Whether it’s managing artists, booking performances, or planning events, our expertise ensures top-tier execution and exceptional results.
    <br/><br/>• We are dedicated to discovering and promoting the next generation of electronic music stars. Our agency offers a supportive platform for new and innovative artists to thrive, providing them with the resources and opportunities they need to succeed on a global stage.
    <br/><br/>• Each artist and event is unique, and we tailor our services to meet your specific needs and vision. Our creative approach ensures that every project we undertake is distinctive and impactful.
        <h1>Break! Did we convince you? <Link to="/contact"><h1 className="animate__animated animate__heartBeat animate__infinite animate__slower contact-us-span">Contact Us!</h1></Link></h1>
    • Our extensive network of industry contacts, venues, and partners means we can open doors and create opportunities that might otherwise be out of reach. We have the connections to get our artists booked at prestigious events and ensure our clients have access to top talent.
    <br/><br/>• Our team excels in planning and executing events that are more than just parties – they are journeys that resonate with audiences and leave a lasting impact.
    <br/><br/>• We believe in the power of music to bring people together and are committed to promoting diversity and inclusivity within the electronic music community. We support a wide range of voices and perspectives, creating a richer, more vibrant cultural landscape.
    <br/><br/>• We value honesty, integrity, and transparency in all our dealings. When you choose Los Bandoleros, you get a reliable partner who communicates openly, delivers on promises, and works to achieve your goals.</p>
        <h1>Now we did? Then <Link to="/contact" className=" contact-us-span">Lets get in touch</Link></h1>
        </div>
    )
}

export default LandingPage