import "animate.css"
import "./main.css"
import artistRoster from "../../components/artists"
import Instagram from "./icons/instagram.svg"
import Facebook from "./icons/facebook.svg"
import Beatport from "./icons/beatport.svg"
import Tiktok from "./icons/tiktok.svg"
import Youtube from "./icons/youtube.svg"
import Website from "./icons/website.svg"
import {useParams} from "react-router-dom"

const ArtistPage = () => {
    const {id} = useParams();
    const filtered = artistRoster.filter((input) =>{
        return input.id == id
    })
    const artist = filtered[0];
    return(
        <div className="artist-screen animate__animated animate__fadeIn">
            <div className="artist-page">
                <img src={artist.image} className="artist-page-image"/>
                <div className="artist-title">{artist.name.toUpperCase()}</div>
                <div className="artist-description">{artist.description}</div>
                { ( artist.soundcloudLink || artist.bandcampLink1 )&& <div className="discography-title">Discography</div>}
                {artist.spotifyLink && <iframe style={{borderRadius:12,borderStyle:"none",margin:16}} src={artist.spotifyLink} width="100%" height="152" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>}
                    {artist.soundcloudLink && <><iframe className="soundcloud" width="100%" height="450" allow="autoplay" src={artist.soundcloudLink}></iframe>
                    <div style={{fontSize: 10, color: "#cccccc",lineBreak: "anywhere",wordBreak: "normal",overflow: "hidden",whiteSpace: "nowrap",textOverflow: "ellipsis",fontWeight: 100}}>
                    </div></>}
                        {artist.bandcampLink1 && <iframe style={{border: 0,width: "100%", height: 120,display:"flex",justifyContent:"center"}} src={artist.bandcampLink1} className="bandcamp-iframe" seamless>
                        </iframe>
                        }
                        {artist.bandcampLink2 && <iframe style={{border: 0,width: "100%", height: 120,display:"flex",justifyContent:"center"}} src={artist.bandcampLink2} className="bandcamp-iframe" seamless>
                        </iframe>}
                        {artist.bandcampLink3 && <iframe style={{border: 0,width: "100%", height: 120,display:"flex",justifyContent:"center"}} src={artist.bandcampLink3} className="bandcamp-iframe" seamless>
                        </iframe>}
                        {artist.beatportLink && <iframe src={artist.beatportLink} width="100%" height="362" style={{width:"100%"}}></iframe>}
                        {('instagram' in artist.socialMedia) && <div className="artist-social-container"> <img className="artist-social-icon"src={Instagram} /> <a className="artist-social-link" href={artist.socialMedia.instagram} target="_blank">Instagram</a> </div>}
                        {('tiktok' in artist.socialMedia) && <div className="artist-social-container"> <img className="artist-social-icon"src={Tiktok} /> <a className="artist-social-link" href={artist.socialMedia.tiktok} target="_blank">Tiktok</a> </div>}
                        {('youtube' in artist.socialMedia) && <div className="artist-social-container"> <img className="artist-social-icon"src={Youtube} /> <a className="artist-social-link" href={artist.socialMedia.youtube} target="_blank">Youtube</a> </div>}
                        {('beatport' in artist.socialMedia) && <div className="artist-social-container"> <img className="artist-social-icon"src={Beatport} /> <a className="artist-social-link" href={artist.socialMedia.beatport} target="_blank">Beatport</a> </div>}
                        {('facebook' in artist.socialMedia) && <div className="artist-social-container"> <img className="artist-social-icon"src={Facebook} /> <a className="artist-social-link" href={artist.socialMedia.facebook} target="_blank">Facebook</a> </div>}
                        {('website' in artist.socialMedia) && <div className="artist-social-container"> <img className="artist-social-icon"src={Website} /> <a className="artist-social-link" href={artist.socialMedia.website} target="_blank">Website</a> </div>}
            </div>
        </div>
    )
}

export default ArtistPage