import useScreenSize from "../../hooks/useScreenSize";
import {Link} from "react-router-dom"
import "./main.css"

const Navbar = () => {
    const screenSize = useScreenSize();
    console.log(screenSize.width)
    return(
        <div className="navbar-container" style={{width:useScreenSize.width}}>
            <Link to="/" className="navbar-button">HOME</Link>
            <Link to="/roster" className="navbar-button">ROSTER</Link>
            <Link to="/about-us" className="navbar-button">ABOUT US</Link>
            <Link to="/contact" className="navbar-button">CONTACT</Link>
        </div>
    )
}
export default Navbar;